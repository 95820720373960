<template>
    <main id="liste" v-cloak>
        <HeaderTab :title="$t('monte.import_dn_as')" />

        <div id="content">
            <div class="container-fluid">
				<div class="box">
					<div class="row">
	                 	<div class="col-6">
							<SeasonInput v-model="seasonSelected" @change="searchContract" />
				        </div>

	                 	<div class="col-6">
				            <e-select
				                v-model="entitySelected"
				                id="entities"
				                track-by="tiers_rs"
				                label="tiers_rs"
				                class="primary"
				                :options="entities"
				                :searchable="true"
				                :show-labels="false"
				                :allow-empty="false"
				                :placeholder="$t('monte.placeholder_entity')"
				                @input="searchContract()"
				            >
				            	<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_stallion_number || $t('monte.stallion_number_not_set') }})</template>
				                <template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_stallion_number || $t('monte.stallion_number_not_set') }})</template>
				                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
				            </e-select>
				        </div>
				    </div>
				    <div class="col-12">
				    	<CustomTable
				    	 	id_table="import_dn_as"
				    	 	ref="table"
				            :items="contracts"
				            :busy.sync="table_busy"
				            primaryKey="contract_id"
					        :hrefsRoutes="config_table_hrefs"
				        />
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">
    import Tiers from "@/mixins/Tiers.js"
    import DPS from "@/mixins/DPS.js"
    import Navigation from "@/mixins/Navigation.js";
    import { EventBus } from 'EventBus'

	export default {
		name: 'ImportDNAS',
		mixins: [Tiers, DPS, Navigation],
		data () {
			return {
				seasonSelected: null,
                entities: [],
                entitySelected: null,
                contracts: [],
                config_table_hrefs: {
					'contract_num': {
						routeUniqueName: 'ContractFormv2',
						routeName: 'ContractFormv2',
						params: {
							contract_id: 'contract_id'
						}
					},
					'last_avenant.horse.horse_nom': {
						routeUniqueName: 'MareFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'last_avenant.avenant_horse'
						}
					},
					'contract_config.horse.horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'contract_config.horse.horse_id'
						}
					}
				},
				events_tab: {
                    'TableAction::goToImportDNAS': this.importDNAS
				},
                table_busy: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
                const entities = await this.loadTiersEntity()
                this.entities = entities.filter(entity => entity.tiers_stallion_number)
                if(this.entities.length > 0) {
	                this.entitySelected = this.entities[0]
	            }
			},

			async searchContract() {
				this.table_busy = true
				this.contracts = await this.loadContractToImportDNAS(this.seasonSelected.id, this.entitySelected.tiers_stallion_number)
				this.table_busy = false
			},

			async importDNAS(contracts) {
				let res = []
				contracts.forEach(contract => {
					res.push({
						contract_id: contract.contract_id,
						dn_url: contract.dn_dispo ? contract.dn_url : null,
						as_url: contract.as_dispo ? contract.as_url : null
					})
				})
				await this.importContractDNAS(this.seasonSelected.id, this.entitySelected.tiers_stallion_number, res)
				EventBus.$emit('TableAction::stopSpin')
				this.$refs.table.refreshTable()
				this.successToast('monte.dn_as_imported')
				this.searchContract()
			}
		},

		components: {
			SeasonInput : () => import('@/components/Inputs/SeasonInput'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}

</script>